module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-E6VWCJGE6Q","cookieName":"aberdeen-bp-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-N6KZDQX","cookieName":"aberdeen-bp-analytics","dataLayerName":"dataLayer"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aberdeen bp","short_name":"Aberdeen bp","start_url":"/","background_color":"#f90","theme_color":"#f90","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"40c85fbaed10abbfc0120d223e282ccd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
